import React from 'react';
import Layout from '../components/Layout'
import { PageHeader } from '../components/PageHeader';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';

const NotFoundPage = (props) => {
  const { data, location } = props;
  const { wordpressWpSettings } = data;
  const { title, wordpressUrl } = wordpressWpSettings;
  return (
    <Layout wordpressUrl={wordpressUrl}>
      <SEO title={`404 | ${title}`} location={location} />
      <PageHeader headerTitle="404 Not Found" headerSubTitle={`The page "${location.pathname}" cannot be found.`} compact={false} location={location} />
    </Layout>
  )
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    wordpressWpSettings {
      title
      wordpressUrl
    }
  }
`
